import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../../components/Menu";

import p1 from "../../images/projects/hm/p1.jpg" 
import p2 from "../../images/projects/hm/p2.jpg" 
import p3 from "../../images/projects/hm/p3.jpg" 
import p4 from "../../images/projects/hm/p4.jpg" 

import GalleryImage from "../../components/GalleryImage";
import Footer from "../../components/Footer";
import Card from "../../components/Card";

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
        <main className="flex flex-wrap flex-row mt-24">
            <Card centered>
                <h1 className="font-superclarendon text-4xl mb-6">
                    H&amp;M Flagshipstore Amsterdam
                </h1>
                <p>An amazing collaboration</p>
            </Card>
            <Card centered backgroundImage={p1} isGallery />
            <Card centered backgroundImage={p2} isGallery />
            <Card centered>
                <p>THIS CAPSULE COLLECTION IN COLLABORATION WITH H&amp;M HAS TO BE VIEWED AS A SAFETY BLANKET ON THE STREETS AGAINST THE COLD WEATHER OF AUTUMN.</p>
                <p>STATEMENT PIECES THAT TAKE THE COSY FEELING OF YOUR HOME TO THE STREETS. </p>
                <p>GET READY TO GO OUTSIDE!</p>
            </Card>
            <Card centered backgroundImage={p3} isGallery />
            <Card centered backgroundImage={p4} isGallery />
        </main>

      <Footer />
    </div>
  );
}

export default Index
